export enum UserRoles {
    ADMIN = 'ADMIN',
    // SUPERADMIN = 'SUPER ADMIN',
    // USER = 'USER',
    // VIEWER = 'VIEWER',
    BLKDOMADMIN = 'BLOCKED DOMAINS ADMIN',
    BLKPRMDOMADMIN = 'BLOCKED AND PREMIUM DOMAIN ADMIN'
}

export enum ContactType {
    PERSON = 'In Person',
    PHONE = 'Phone',
    EMAIL = 'Email',
}
