import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from 'src/api/authentication.service';



@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn.pipe(
      take(1),
      map(() => {
        const expectedRole:string[] = next.data.expectedRoles;
        const token = localStorage.getItem('token');
        const role = JSON.parse(localStorage.getItem('role'));
        let flag: boolean;
        if (role) {
           flag = expectedRole.includes(role.toUpperCase());
        }
        if (token == null || !flag) {
          this.router.navigate(['/authentication/signin']);
          return false;
        }
        return true;
      })
    );
  }
}