import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard/usage-dashboard',
    title: 'Dashboard',
    icon: 'fas fa-tachometer-alt',
    class: 'menu-toggle',
    submenu: []
  },
  {
    path: '/tables/api-usage',
    title: 'Domain Search Engine',
    icon: 'fas fa-clipboard-list',
    class: 'menu-toggle',
    submenu: [      
      {
        path: '/tables/api-usage',
        title: 'Api Usage',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
  

        submenu: []
      },
      {
        path: '/tables/web-job-logs',
        title: 'Web Job Logs',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',

        submenu: []
      }, 
      {
        path: '/tables/blocked-domains',
        title: 'Blocked Domains',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',

        submenu: []
      },
      {
        path: '/tables/focused-domains',
        title: 'Focused Domains',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',

        submenu: []
      },  
      {
        path: '/dashboard/extension-counts',
        title: 'Extension Counts',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',

        submenu: []
      },  
      {
        path: '/tables/domain-allowed-chars',
        title: 'Domain Allowed Characters',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',

        submenu: []
      },  
      {
        path: '/tables/language-regex',
        title: 'Language-RegEx Mapping',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',

        submenu: []
      }, 
      {
        path: '/tables/extension-language',
        title: 'Extensions-Languages Mapping',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      }, 
    ]
  },
  {
    path: '/tables/api-usage',
    title: 'Premium Domains',
    icon: 'fas fa-chess-king',
    class: 'menu-toggle',
    submenu: [      
      {
        path: '/tables/premium-domains-api-usage',
        title: 'Api Usage',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
        submenu: []
      },
      {
        path: '/tables/premium-domains-webjob-logs',
        title: 'Web Job Logs',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
        submenu: []
      }, 
      {
        path: '/tables/premium-domains',
        title: 'Premium Domains',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      },
      {
        path: '/tables/premium-domains-multi-ext',
        title: 'Multi Ext Domains File',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      }, 
      {
        path: '/tables/premium-domains-manual-edits',
        title: 'Manual Edits',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      }, 
    ]
  },
  {
    path: '/tables/uspto-api-usage',
    title: 'USPTO Search Engine',
    icon: 'fas fa-clipboard-list',
    class: 'menu-toggle',
    submenu: [
      {
        path: '/tables/uspto-api-usage',
        title: 'USPTO Api Usage',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',

        submenu: []
      },
      {
        path: '/tables/uspto-da-logs',
        title: 'Daily Appl. Import Log',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',

        submenu: []
      },
      {
        path: '/dashboard/uspto-qty-tm',
        title: 'Quantity Trademark Graph',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',

        submenu: []
      },
      {
        path: '/tables/uspto-tmog-logs',
        title: 'Official Gazette Import Log',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
        submenu: []
      },
      {
        path: '/tables/uspto-tm-image-logs',
        title: 'TM Logo Import Log',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
        submenu: []
      }
    ]
  },
  {
    path: '/tables/User-list',
    title: 'User',
    icon: 'fas fa-users',
    class: 'menu-toggle',
    submenu: []
  },
  {
    path: '/tables/api-list',
    title: 'API Master',
    icon: 'fas fa-atlas',
    class: 'menu-toggle',
    submenu: []
  },
  {
    path: '/tables/job-list',
    title: 'Web Job Master',
    icon: 'fas fa-atlas',
    class: 'menu-toggle',
    submenu: []
  },
  {
    path: '/tables/error-logs',
    title: 'Error Logs',
    icon: 'fas fa-list-alt',
    class: 'menu-toggle',
    submenu: []
  }, 
  {
    path: '/tables/subscription-list',
    title: 'Subscriptions',
    icon: 'fas fa-user-plus',
    class: 'menu-toggle',
    submenu: []
  },  
];

export const ROUTESBLOCKADMIN: RouteInfo[] = [
  {
    path: '/dashboard/usage-dashboard',
    title: 'Dashboard',
    icon: 'fas fa-tachometer-alt',
    class: 'menu-toggle',
    submenu: []
  },
  {
    path: '/tables/api-usage',
    title: 'Domain Search Engine',
    icon: 'fas fa-clipboard-list',
    class: 'menu-toggle',
    submenu: [      
      {
        path: '/tables/api-usage',
        title: 'Api Usage',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
        submenu: []
      },
      {
        path: '/tables/web-job-logs',
        title: 'Web Job Logs',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
        submenu: []
      }, 
      {
        path: '/tables/blocked-domains',
        title: 'Blocked Domains',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      },
      {
        path: '/tables/focused-domains',
        title: 'Focused Domains',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      },  
      {
        path: '/dashboard/extension-counts',
        title: 'Extension Counts',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      },  
      {
        path: '/tables/domain-allowed-chars',
        title: 'Domain Allowed Characters',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      },  
      {
        path: '/tables/language-regex',
        title: 'Language-RegEx Mapping',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      }, 
      {
        path: '/tables/extension-language',
        title: 'Extensions-Languages Mapping',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      }, 
    ]
  },

  
];


export const ROUTESPREMBLOCKADMIN: RouteInfo[] = [
  {
    path: '/dashboard/usage-dashboard',
    title: 'Dashboard',
    icon: 'fas fa-tachometer-alt',
    class: 'menu-toggle',
    submenu: []
  },
  {
    path: '/tables/api-usage',
    title: 'Domain Search Engine',
    icon: 'fas fa-clipboard-list',
    class: 'menu-toggle',
    submenu: [      
      {
        path: '/tables/api-usage',
        title: 'Api Usage',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
  
        submenu: []
      },
      {
        path: '/tables/web-job-logs',
        title: 'Web Job Logs',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
        submenu: []
      }, 
      {
        path: '/tables/blocked-domains',
        title: 'Blocked Domains',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      },
      {
        path: '/tables/focused-domains',
        title: 'Focused Domains',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      },  
      {
        path: '/dashboard/extension-counts',
        title: 'Extension Counts',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      },  
      {
        path: '/tables/domain-allowed-chars',
        title: 'Domain Allowed Characters',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      },  
      {
        path: '/tables/language-regex',
        title: 'Language-RegEx Mapping',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      }, 
      {
        path: '/tables/extension-language',
        title: 'Extensions-Languages Mapping',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',        
        submenu: []
      }, 
    ]
  },
  {
    path: '/tables/api-usage',
    title: 'Premium Domains',
    icon: 'fas fa-chess-king',
    class: 'menu-toggle',
    submenu: [      
      {
        path: '/tables/premium-domains-api-usage',
        title: 'Api Usage',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
        submenu: []
      },
      {
        path: '/tables/premium-domains-webjob-logs',
        title: 'Web Job Logs',
        icon: 'fas fa-clipboard-list',
        class: 'menu-toggle',
        submenu: []
      }, 
      {
        path: '/tables/premium-domains',
        title: 'Premium Domains',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      }, 
      {
        path: '/tables/premium-domains-multi-ext',
        title: 'Multi Ext Domains File',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      }, 
      {
        path: '/tables/premium-domains-manual-edits',
        title: 'Manual Edits',
        icon: 'fas fa-exclamation-triangle',
        class: 'menu-toggle',
        submenu: []
      }, 
    ]
  },

  
];