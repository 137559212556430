import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class ConfigsLoaderService {

  private httpClient: HttpClient;
  public configs: Configs;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public async loadConfigs(): Promise<any> {
    return this.httpClient.get('assets/config/configSettings.json').pipe(settings => settings)
      .toPromise()
      .then(settings => {
        this.configs = settings as Configs;
      });
  }
}

export interface Configs {
  apiURL: string
}
