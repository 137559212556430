import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { from, Observable, BehaviorSubject, config } from 'rxjs';
import { Router } from '@angular/router';
import { User } from 'src/shared/User';
import { ApiResponse } from 'src/shared/ApiResponse';
import { ConfigsLoaderService } from './configsloader.service';

@Injectable()
export class AuthenticationService {
  public currentUser: Observable<User>;
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  private forgotPass = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private router: Router, private settings: ConfigsLoaderService) {
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  }

  httpOptionsLogout = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token'))
    })
  }

  login(username: string, password: string) {
    var model = {
      requestID: "string",
      applicationID: "string",
      userName: username,
      password: password
    };
    this.loggedIn.next(true);
    let url = this.settings.configs.apiURL + "users/login";
    return this.http.post<ApiResponse>(url, model, this.httpOptions);
  }

  PortalLogOutStatus(userId: string) {
    var model = { Password: "", UserName: userId };
    let url = this.settings.configs.apiURL + "/Account/PortalLogOutStatus";
    return this.http.post<ApiResponse>(url, model, this.httpOptions);
  }

  forgotPassword(username: string) {
    this.forgotPass.next(true);
    let url = this.settings.configs.apiURL + "/Account/ForgotPassword?email=" + username;
    return this.http.get<ApiResponse>(url, this.httpOptions);
  }

  logout() {
    this.loggedIn.next(false);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  hasToken(): boolean {
    var accesToken = localStorage.getItem('token');
    if (accesToken != null) {
      return true;
    }
    return false;
  }
} 